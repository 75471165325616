
import { Vue, Component, Prop } from 'vue-property-decorator';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import axios from 'axios';

import { Auth } from '@/store/modules';

@Component({
  name: "UploadImageDialog",
  components: {
    VueCropper
  }
})
export default class extends Vue {
  @Prop() id!: string;
  @Prop() type!: 'user_avatar' | 'teacher_avatar' | 'class_image';
  @Prop() aspectRatio?: number;
  @Prop({ default: false }) multiple!: boolean;
  @Prop({ default: false }) isOldDialog?: boolean;
  dialog = false;
  file = null as any;
  imgSrc = '/class-placeholder.png';
  imgSrcs: string[] = [];
  progress = 0;
  uploading = false;
  loading = true;

  open() {
    this.file = null;
    this.imgSrc = '/class-placeholder.png';
    this.imgSrcs = [];
    this.uploading = false;
    this.loading = true;
    (this.$refs.browseInput as any).value = null;
    (this.$refs.browseInput as any).click();
    this.progress = 0;
  }

  setImage(e: any) {
    this.dialog = true;

    if (this.isOldDialog) {
      const file = e.target.files[0] as File;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.imgSrc = (event.target?.result) as string;
        (this.$refs.cropper as any).replace(event.target?.result);
        this.loading = false;
      }

      reader.readAsDataURL(file);
    }
    else {
      const files = e.target.files as File[];
      files.forEach((file, i) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const imgSrc = event.target?.result as string
          this.$set(this.imgSrcs, i, imgSrc);
          (this.$refs.cropper as any).replace(event.target?.result);
        }

        reader.readAsDataURL(file);
      });
    }

    this.loading = false;
  }

  async upload() {
    this.uploading = true;
    try {
      const blob = await new Promise((resolve) => {
        (this.$refs as any).cropper
          .getCroppedCanvas({
            maxWidth: 1920,
            maxHeight: 1080
          })
          .toBlob((blob: Blob) => resolve(blob), "image/jpeg", 0.9);
      });

      const data = new File([blob as Blob], 'image.jpg');

      const uploadUrl = await Auth.getUploadUrl({
        type: this.type,
        id: this.id,
      });

      await axios.put(uploadUrl, data, {
        onUploadProgress: (progress) => this.progress = (progress.loaded / progress.total) * 100,
        headers: {
          'Content-Type': 'image/jpeg',
        }
      });
      this.$emit('upload', `${uploadUrl.substr(0, uploadUrl.indexOf('?'))}?q=${Date.now()}`);
      this.dialog = false;
    } catch (err) {
      console.log(err.message);
    }
    this.uploading = false;
  }
}
